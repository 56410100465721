import "core-js/modules/es6.array.map.js";
import "core-js/modules/es6.array.filter.js";
import "core-js/modules/es6.string.includes.js";
import "core-js/modules/es7.array.includes.js";
import { sustainabilityIndictatorDescription } from '~/functions/sfdr';
import { useShowDebugging } from '~/functions/accounts';
import { ConceptKind, ConceptStatus, useAddConceptMutation, useGetAccountQuery, useUpdateSfdrStrategyMutation, FundStrategy } from '~/types/types';

// TODO ISOLATE THIS COMPONENT TO DECOUPLE FROM PARENTS.
// TODO: PASS ONLY OBJECTIVE ID (MAYBE FUND ID)
export default defineComponent({
  props: {
    sustainableObjective: {
      type: Object,
      required: true
    },
    fund: {
      type: Object,
      required: true
    },
    portfolioId: {
      type: String,
      required: true
    }
  },
  setup: function setup(props, _ref) {
    var emit = _ref.emit;
    var _useUpdateSfdrStrateg = useUpdateSfdrStrategyMutation(),
      updateSfdrStrategy = _useUpdateSfdrStrateg.mutate;
    var _useAddConceptMutatio = useAddConceptMutation(),
      addConcept = _useAddConceptMutatio.mutate;
    var sfdrStrategy = computed(function () {
      return props.sustainableObjective || {};
    });

    // Use this to fetch all venture data.
    // Portfolio is from getFund which does not include venture names.
    // This query will be cached. So no need to worry about performance.
    var _useGetAccountQuery = useGetAccountQuery({
        id: props.portfolioId || ''
      }),
      result = _useGetAccountQuery.result;
    var allVentureLinks = computed(function () {
      var _result$value$getAcco, _result$value, _result$value$getAcco2, _result$value$getAcco3;
      return (_result$value$getAcco = (_result$value = result.value) === null || _result$value === void 0 ? void 0 : (_result$value$getAcco2 = _result$value.getAccount) === null || _result$value$getAcco2 === void 0 ? void 0 : (_result$value$getAcco3 = _result$value$getAcco2.ventureLinks) === null || _result$value$getAcco3 === void 0 ? void 0 : _result$value$getAcco3.map(function (e) {
        return e.venture;
      })) !== null && _result$value$getAcco !== void 0 ? _result$value$getAcco : [];
    });
    var showDebugging = useShowDebugging();
    var isArticle9 = computed(function () {
      var _props$fund;
      if (((_props$fund = props.fund) === null || _props$fund === void 0 ? void 0 : _props$fund.fundStrategy) === 'ARTICLE_9') return true;else return false;
    });

    // Ventures in this fund.
    var ventureIdsInFund = computed(function () {
      var _props$fund$ventureId, _props$fund2;
      return (_props$fund$ventureId = props === null || props === void 0 ? void 0 : (_props$fund2 = props.fund) === null || _props$fund2 === void 0 ? void 0 : _props$fund2.ventureIds) !== null && _props$fund$ventureId !== void 0 ? _props$fund$ventureId : [];
    });

    // These are IDs of ventures assigned to THIS sustainable objective (S.O.).
    var ventureIdsInThisObjective = computed(function () {
      var _sfdrStrategy$value;
      return (sfdrStrategy === null || sfdrStrategy === void 0 ? void 0 : (_sfdrStrategy$value = sfdrStrategy.value) === null || _sfdrStrategy$value === void 0 ? void 0 : _sfdrStrategy$value.ventureIds) || [];
    });

    // These are ventures assigned to this S.O. AND to another S.O.
    var redFlagVentures = computed(function () {
      var _sfdrStrategy$value2;
      var currentSOVentureIds = (sfdrStrategy === null || sfdrStrategy === void 0 ? void 0 : (_sfdrStrategy$value2 = sfdrStrategy.value) === null || _sfdrStrategy$value2 === void 0 ? void 0 : _sfdrStrategy$value2.ventureIds) || [];
      // Find ventures that are assigned to the current S.O. and another S.O.
      var redFlagIds = currentSOVentureIds.filter(function (ventureId) {
        var _props$fund3;
        return (_props$fund3 = props.fund) === null || _props$fund3 === void 0 ? void 0 : _props$fund3.sustainableObjectives.some(function (objective) {
          var _sfdrStrategy$value3;
          return objective.id !== (sfdrStrategy === null || sfdrStrategy === void 0 ? void 0 : (_sfdrStrategy$value3 = sfdrStrategy.value) === null || _sfdrStrategy$value3 === void 0 ? void 0 : _sfdrStrategy$value3.id) && objective.ventureIds && objective.ventureIds.includes(ventureId);
        });
      });
      // Filter these ventures as complete objects from allVentures
      return allVentureLinks.value.filter(function (link) {
        return redFlagIds.includes(link === null || link === void 0 ? void 0 : link.id);
      });
    });
    var handleConceptInput = function handleConceptInput(value) {
      if (value === '' || value === null) {
        emit('updateStrategy', sfdrStrategy.value.id, 'conceptId', null);
      } else if (typeof value === 'string') {
        var input = {
          label: value,
          createdBy: props.portfolioId,
          status: ConceptStatus.Proposed,
          kind: ConceptKind.Impact,
          sourceUrl: '',
          citation: ''
        };
        addConcept({
          input: input
        }).then(function (result) {
          var _result$data;
          if (result !== null && result !== void 0 && (_result$data = result.data) !== null && _result$data !== void 0 && _result$data.addConcept) {
            var concept = result.data.addConcept;
            emit('updateStrategy', sfdrStrategy.value.id, 'conceptId', concept.id);
          }
        });
      } else {
        emit('updateStrategy', sfdrStrategy.value.id, 'conceptId', value.id);
      }
    };
    var isMitigationOrAdaptationGoal = computed(function () {
      var _sfdrStrategy$value$c;
      // Returns true if ixId matches mitigation or adaptation.
      var ixId = (_sfdrStrategy$value$c = sfdrStrategy.value.concept) === null || _sfdrStrategy$value$c === void 0 ? void 0 : _sfdrStrategy$value$c.ixId;
      return ixId ? ['climateChangeMitigation', 'climateChangeAdaptation'].includes(ixId) : false;
    });
    return {
      updateSfdrStrategy: updateSfdrStrategy,
      addConcept: addConcept,
      handleConceptInput: handleConceptInput,
      isArticle9: isArticle9,
      sfdrStrategy: sfdrStrategy,
      ventureIdsInFund: ventureIdsInFund,
      redFlagVentures: redFlagVentures,
      ventureIdsInThisObjective: ventureIdsInThisObjective,
      isMitigationOrAdaptationGoal: isMitigationOrAdaptationGoal,
      FundStrategy: FundStrategy,
      showDebugging: showDebugging
    };
  },
  data: function data() {
    return {
      sustainableObjectiveOptions: [{
        header: 'Create a sustainable objective of your choice or select one of the options below.'
      }, {
        id: 13613,
        label: 'Climate change mitigation (EU Taxonomy objective)'
      }, {
        id: 13738,
        label: 'Climate change adaptation (EU Taxonomy objective)'
      }, {
        id: 13741,
        label: 'The sustainable use and protection of water and marine resources (EU Taxonomy objective)'
      }, {
        id: 13611,
        label: 'Transition to a circular economy (EU Taxonomy objective)'
      }, {
        id: 13739,
        label: 'Pollution prevention and control (EU Taxonomy objective)'
      }, {
        id: 13740,
        label: 'Protection and restoration of biodiversity and ecosystems (EU Taxonomy objective)'
      }],
      sustainabilityIndictatorDescription: sustainabilityIndictatorDescription
    };
  }
});